/// <reference path="../../../typings/libraries.d.ts"/>

const isDebug = !!window.debugMode;
const isPublic = document.body.classList.contains('public');
const primaryWidgetOuterElement = document.getElementById('primary_widget_outer');

if (!isPublic && primaryWidgetOuterElement) {
    // Create the widget overlay element
    const widgetOverlayElement = document.createElement('div');
    widgetOverlayElement.id = 'widget_overlay';

    // Create the loader element and style it appropriately.
    const loadingElement = document.createElement('div');
    loadingElement.classList.add('loading');
    loadingElement.innerText = 'Please wait while we load your info.';

    // Append the elements to the DOM.
    widgetOverlayElement.appendChild(loadingElement);
    primaryWidgetOuterElement.appendChild(widgetOverlayElement);
}

// Load Ad Blocker Detection Script
if (!isPublic && Alkami.Utils.StorageHelper.hasLocalStorage() && !localStorage.getItem('adBlockerPromptSeen')) {
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.defer = true;
    script.src = '../javascripts/shared.scripts/ad-blocker-detection-ts.min.js';
    document.getElementsByTagName('head')[0].appendChild(script);
}

if (Alkami.Globals.localeHasChanged) {
    if (Alkami.WidgetNav) {
        Alkami.WidgetNav.ResetWidgetNav();
    }
    if (Alkami.WidgetHeader) {
        Alkami.WidgetHeader.resetHeaderInfoStorage();
    }
    if (Alkami.LanguageToggle) {
        Alkami.LanguageToggle.ResetLocales();
    }
}

Alkami.Dom.onDocumentReady(() => {
    // If we are in debug mode, overlay something in the layout to expose that we are in debug.
    if (isDebug) {
        document.body.classList.add('debug');
        document.body.style.overflowX = 'hidden';
    }

    // Now that the document has loaded, remove the widget loading overlay.
    const overlayElement = document.getElementById('widget_overlay');

    if (overlayElement) {
        overlayElement.remove();
    }

    document.getElementById('primary_widget_content').style.visibility = 'visible';

    // If this view has a sidebar, add a class to the widget outer wrapper.
    const sidebarElement = document.getElementById('sidebar');

    if (sidebarElement && primaryWidgetOuterElement) {
        primaryWidgetOuterElement.classList.add('has_sidebar');
    }

    // Add a class on all input fields that cooresponds to its type
    const allInputElements = document.querySelectorAll('input');

    Array.from(allInputElements)
        .forEach((inputElement) => {
            inputElement.classList.add(inputElement.type);
        });

    // Insert a <br> tag at the end of all elements with a clearfix class
    Array.from(document.querySelectorAll('.clearfix'))
        .forEach((element) => {
            const breakElement = document.createElement('br');
            breakElement.classList.add('clear');

            element.appendChild(breakElement);
        });

    // Bind behavior on input fields.
    bindDecimalFields();
    bindRequiredFields();

    const numberTextFieldElements = document.querySelectorAll('input[type="text"].number') as NodeListOf<HTMLElement>;

    if (numberTextFieldElements.length > 0) {
        Array.from(numberTextFieldElements)
            .forEach((inputElement) => {
                inputElement.addEventListener('keypress', (event: KeyboardEvent) => {
                    const noNegative = inputElement.classList.contains('no-negative');

                    if (event.which == 0 || event.which == Alkami.Helpers.KEY_CODES.DELETE || event.which == Alkami.Helpers.KEY_CODES.BACKSPACE || event.which == Alkami.Helpers.KEY_CODES.PERIOD || event.keyCode == Alkami.Helpers.KEY_CODES.TAB || event.which == Alkami.Helpers.KEY_CODES.COMMA) return true;
                    if ((event.which == Alkami.Helpers.KEY_CODES.MINUS || Alkami.Helpers.KEY_CODES.DASH) && noNegative) return false;
                    if ((event.which == Alkami.Helpers.KEY_CODES.MINUS || Alkami.Helpers.KEY_CODES.DASH) && /\-/.test(this.value)) return false; // minus key and already has minus
                    if (event.which >= 44 && event.which <= 57) return true;
                    return false;
                });
            });
    }

    // Make all `post-link` anchor elements POSTable
    const postLinkHandler = Alkami.Dom.passiveEventListener('a.post-link', (event) => {
        if (!this.href) { return; }

        event.preventDefault();
        event.stopPropagation();

        Alkami.Helpers.Form.postLink(this.href);
    });

    document.addEventListener('click', postLinkHandler);

    // jQuery plugin initialization
    // TODO: See if any of these can be converted to a pure JS implementation.
    $('.filter-slide').contentSlider();
    $('.filter-slide-open').contentSlider({ collapsedByDefault: false });
    $('.title-tooltip').toolTip({ useTitle: true, selector: '.title-tooltip' });
    $('.tooltip').toolTip({ selector: '.tooltip' });

    // Modifying the prototype to change how the progressbar displays on screen.
    $.ui.progressbar.prototype.min = -1;

    // Show flash notitfications if there are any flash-holder elements on the page.
    const flashHolderElements = document.querySelectorAll('.flash-holder') as NodeListOf<HTMLElement>;

    if (flashHolderElements.length > 0) {
        Array.from(flashHolderElements)
            .forEach((element) => {
                const message = element.innerText;

                if (message) {
                    const hasError = element.classList.contains('error');
                    const hasInfo = element.classList.contains('info');
                    const hasSecurity = element.classList.contains('__security__');

                    // Once we've pulled the message from the div, remove it from the DOM.
                    element.remove();

                    // Based on the message type, call the appropriate function to show the message in a notification.
                    if (!hasSecurity && (message !== "System.Object")) {
                        if (hasError) {
                            Alkami.FlashBanner.showError(message);
                        } else if (hasInfo) {
                            Alkami.FlashBanner.showInfo(message);
                        } else {
                            Alkami.FlashBanner.showMessage(message);
                        }
                    }
                }
            });
    }

    // Simple Informational Dialog/Modal
    // TODO: Currently this is only used in budgets.
    const infoPopupElement = document.querySelector('.info-popup') as HTMLAnchorElement;

    if (infoPopupElement) {
        infoPopupElement.addEventListener('click', (event) => {
            // NOTE: The href on the element is in the form of '#some_div_id' which we then use as a selector to pull the html from.
            // TODO: Convert this away from using an ext modal in favor of an iris prompt.
            const contentSelector = infoPopupElement.hash.replace('#', '');
            const popupContentSectionElement = Alkami.Dom.parseHTML(document.getElementById(contentSelector).innerHTML).firstChild;

            Alkami.Helpers.createCloseDialog(popupContentSectionElement, { title: infoPopupElement.title });
        });
    }

    // Sets bootstrap modal default settings
    if ($.fn.modal && ($.fn.modal as any).Constructor) {
        ($.fn.modal as any).Constructor.DEFAULTS.backdrop = 'static';
    }

    // Wrap all clearable input fields in a wrapper.
    const clearableInputElements = document.querySelectorAll('input.clearable') as NodeListOf<HTMLInputElement>;

    if (clearableInputElements) {
        Array.from(clearableInputElements)
            .forEach((inputElement) => {
                const wrapperElement = document.createElement('div');
                wrapperElement.classList.add('search-wrapper');

                const clearInputElement = document.createElement('span');
                clearInputElement.classList.add('clear-input', 'font-icon-canceled');
                clearInputElement.setAttribute('aria-role', 'button');
                clearInputElement.setAttribute('aria-label', 'Clear input field.');

                inputElement.parentNode.insertBefore(wrapperElement, inputElement);
                wrapperElement.appendChild(inputElement);
                wrapperElement.appendChild(clearInputElement);

                // Attach event listeners
                // If there is a value that is not an empty string, add the "input-filled" class
                inputElement.addEventListener('input', (event) => {
                    inputElement.classList.toggle('input-filled', !!inputElement.value);
                });

                // When the clear icon is clicked, clear out the input's value
                clearInputElement.addEventListener('click', (event) => {
                    inputElement.classList.remove('input-filled');
                    inputElement.value = '';
                    inputElement.focus();
                });
            });
    }

    // PDF confirmation listeners
    // HACK: This is really terrible. Why do we have custom logic for specific disclosures in the primary JS file for the application.
    // TODO: Come back and refactor to move these to the disclosure views individually.
    document.addEventListener('change', (event: Event) => {
        const targetId = (event.target as HTMLElement).id;
        if (!targetId) {
            return;
        }

        // Essentially a list of all the disclosure section checkbox element ids.
        // When a change event happens we search the array for an id that matches the event target's id.
        // If a match is found, open the access code section for the user.
        const disclosureCheckboxIds = [
            'DisclosureAccepted',
            'agree_to_terms',
            'ReceivesFIStatementAlerts_IsChecked'
        ];

        if (disclosureCheckboxIds.indexOf(targetId) > -1) {
            const accessCodeElement = document.getElementById('pdf_access');

            if (accessCodeElement) {
                Alkami.Dom.slideToggle(accessCodeElement, 400);
            }
        }
    });

    // Browser Support Link
    let BrowserSupportPromise: Promise<HTMLScriptElement>;

    async function openBrowserSupport(oldHash = '') {
        if (!BrowserSupportPromise) {
            BrowserSupportPromise = Alkami.Cdn.scriptWithCacheExpiration('/javascripts/shared.scripts/browser-support-ts.js');
        }

        await BrowserSupportPromise;
        const prompt = Alkami.BrowserSupport.displaySupportInfo();
        prompt.element.addEventListener('iris.prompt.closed', () => {
            location.hash = oldHash;
        });
    }

    //Check if the old Browser Support page exists on the page.
    if (!(window as any).BrowserInfo) {
        // preload browserSupport page
        if (!Alkami.BrowserSupport) {
            BrowserSupportPromise = Alkami.Cdn.scriptWithCacheExpiration('/javascripts/shared.scripts/browser-support-ts.js');
        }

        window.addEventListener('hashchange', function (event) {
            const { oldURL } = event as HashChangeEvent;
            const oldHash = oldURL.split('#')[1] || '';
            if (location.hash === '#/browser-support') {
                openBrowserSupport(oldHash);
            }
        });
        if (location.hash === '#/browser-support') {
            openBrowserSupport();
        }
    }
});
